import React from "react";
import signUpImage from "./resources/landingImage.png"
import styled from 'styled-components';
import {useHistory, Redirect} from 'react-router-dom';
import {useSigninCheck} from 'reactfire';
import {Waves} from './Waves';
import {theme} from './styles';


const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  margin: auto;
  padding: 10vh 1em 0 1em;

  @media (max-width: 600px) {
    flex-direction: column;
    height: calc(95vh - 4em);
  }

  ::after {
    content: "";
    background: url("./home_scene.svg");
    opacity: 0.12;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: end;
`

const CtaHeaderText = styled.h1`
  font-weight: 900;
  font-size: 5em;

  @media (max-width: 900px) {
    font-size: 4em;
  }

  @media (max-width: 600px) {
    line-height: 0;
    font-size: 3em;
  }
`

const LandingPageImageContainer = styled.div`
  padding-left: 1em;
  place-self: center center;
`

export const LargeImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 80vh;
  object-fit: contain;
  //z-index: 10;
  position: relative;
`

const WaveContinue = styled.div`
  height: 80vh;
  width: 100vw;
  margin-top: -1em;
  display: grid;
  place-items: center;
  position: relative;
  background-color: ${props => props.theme.tertiaryColor};
`

export const SignUpButton = styled.button`
  border-radius: 0.3em;
  color: ${props => props.theme.textColor};
  font-size: 1.2em;
  padding: 0.8em;
  border: 2px solid ${props => props.theme.textColor};
  background: none;
  font-weight: bold;

  max-width: 300px;
  z-index: 40;

  :hover {
    cursor: pointer;
    background: #FFFFFF13;
  }
`

export const LandingPage = (props) => {
    const {signUpAction, invited, poolName} = props;
    const history = useHistory();

    const {status, data: {signedIn}} = useSigninCheck();

    async function loginWithGoogle() {
        if (signedIn) {
            history.push('/home');
        } else {
            history.push('/sign-up')
        }
    }

    if (signedIn) {
        return <Redirect to={{pathname: '/home'}}/>
    }

    return (
        <React.Fragment>
            <LandingPageContainer>
                <CtaContainer>
                    <CtaHeaderText>losing.rocks</CtaHeaderText>
                    <h3>
                        lose weight together
                    </h3>
                    <h3>
                        {invited
                            ? 'You were invited to join a pool!'
                            : ''
                        }
                    </h3>
                    <SignUpButton onClick={signUpAction ?? loginWithGoogle}>
                        {signedIn
                            ? 'Go Home'
                            : 'Sign Up'
                        }
                    </SignUpButton>
                </CtaContainer>
                <LandingPageImageContainer>
                    <LargeImage src={signUpImage}/>
                </LandingPageImageContainer>
            </LandingPageContainer>
            <Waves position='sticky' color={theme.tertiaryColor}/>
            <WaveContinue>
                <CtaContainer>
                    <CtaHeaderText>Lose Together</CtaHeaderText>
                    <h3>
                        create a pool and invite your friends. win by dropping like a rock.
                    </h3>
                    <SignUpButton onClick={signUpAction ?? loginWithGoogle}>
                        {signedIn
                            ? 'Go Home'
                            : 'Let\'s make it official'
                        }
                    </SignUpButton>
                </CtaContainer>
            </WaveContinue>
            <Waves position='relative' color={theme.primaryLight} backgroundColor={theme.tertiaryColor}/>
        </React.Fragment>
    )
}