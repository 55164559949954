import styled, {createGlobalStyle, css} from 'styled-components';
import {Link} from 'react-router-dom';
import {firebaseUiStyles} from './auth/firebase-styles';
import {reactDatepickerStyles} from './datepicker-styles';

const colors = {
    primaryLight: '#26adb5',
    primaryLighter: '#9ae4ec',
    primaryDark: '#3b1864',
    secondaryLight: '#ffd615',
    secondaryColor: '#575151',
    tertiaryDark: '#4d7fe0',
    tertiaryColor: '#f1a140',
}

export const darken = (color) => `${color}77`

export const theme = {
    ...colors,
    textColor: colors.primaryDark,
    backgroundColor: colors.primaryLight,
    successColor: '#00C781',
    dangerColor: '#FF4040',
}

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box
  }

  @font-face {
    font-family: 'Recoleta';
    src: url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba33e231105578ad263_38297D_1_0.woff2') format('woff2'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba37aefa423720f072b_38297D_1_0.woff') format('woff'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba3e59b2e6d27078a7d_38297D_1_0.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  
  @font-face {
    font-family: 'Recoleta';
    src: url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba337bb3005b4c061ea_38297D_0_0.woff2') format('woff2'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba39919a534b5506fcf_38297D_0_0.eot') format('embedded-opentype'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba37aefa4fd6c0f072a_38297D_1_0.eot') format('embedded-opentype'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba37aefa4dc5d0f0729_38297D_0_0.woff') format('woff'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c002ba373c94603e93c9a6a_38297D_0_0.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }
  
  @font-face {
    font-family: 'Recoleta';
    src: url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c017af219ff773d1e481de1_382B36_6_0.woff2') format('woff2'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c017af2c0ae3627a2b857ce_382B36_6_0.eot') format('embedded-opentype'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c017af27cd95a9c69dcbaf9_382B36_6_0.woff') format('woff'), url('https://assets.website-files.com/5bff8886c3964a992e90d465/5c017af37cd95a2238dcbafa_382B36_6_0.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  select, input, textarea, button {
    font-family: "Recoleta", serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  h2, h3, h4, h5 {
    font-weight: 400;
  }

  html, body {
    font-family: "Recoleta", sans-serif;
    background-color: ${props => props.theme.primaryLight};
    color: ${props => props.theme.textColor};
    margin: 0;
    height: 100%;
    font-weight: 400;
    font-variant-ligatures: none;
  }

  i:hover {
    cursor: help;
  }

  a {
    color: ${props => props.theme.textColor};
    text-decoration: none;
  }

  pre {
    font-family: Monaco, SFMono-Regular, sans-serif;
  }

  ${firebaseUiStyles};
  ${reactDatepickerStyles};
`

export const shadow = css`
  box-shadow: 3px 3px 25px #ffffff1C;
`

export const buttonCss = css`
  color: ${props => props.theme.textColor};
  border-radius: 0.3em;
  border: none;
  padding: 0.5em 1.5em;
  margin: 1em;
  min-width: 100px;

  transition: all 0.2s;
  font-size: 1em;
  box-sizing: border-box;
  text-align: center;
  
  ${shadow};

  :hover {
    cursor: ${props => props.disabled ? 'not-allowed': 'pointer'};
  }

  :focus {
    outline: none;
  }
`

export const InlineLink = styled(Link)`
  text-decoration: underline;
`

export const ButtonLink = styled(Link)`
  ${buttonCss};
  background: ${props => props.disabled ? props.theme.textColor + 'cf' : props.theme.textColor};
  color: ${props => props.theme.primaryLighter};
  
  display: grid;
  place-items: center;
  text-align: center;
  
  :hover {
    background: ${props => props.theme.textColor + 'cf' };
  }
`

export const InvertedButtonLink = styled(Link)`
  ${buttonCss};
  background: ${props => props.disabled ? props.theme.backgroundColor + 'cf' : props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  
  display: grid;
  place-items: center;
  
  :hover {
    background: ${props => props.theme.backgroundColor + 'cf' };
  }
`

export const PrimaryButton = styled.button`
  ${buttonCss};
  
  background: ${props => props.disabled ? props.theme.backgroundColor + 'cf' : props.theme.backgroundColor};

  :hover {
    background: ${props => props.theme.backgroundColor + 'cf'};
    cursor: pointer;
  }
`

export const TertiaryButton = styled.button`
  ${buttonCss};
  
  color: ${props => props.theme.primaryDark};
  background: ${props => props.disabled ? props.theme.tertiaryColor + 'cf' : props.theme.tertiaryColor};

  :hover {
    background: ${props => props.theme.tertiaryColor + 'cf'};
    cursor: pointer;
  }
`


export const ConfirmButton = styled.button`
  ${buttonCss};
  background: ${props => props.theme.successColor};

  background: ${props => props.disabled ? props.theme.successColor + 'cf' : props.theme.successColor};

  :hover {
    background: ${props => props.theme.successColor + 'cf'};
    cursor: pointer;
  }
`

export const RejectButton = styled.button`
  ${buttonCss};
  background: ${props => props.theme.dangerColor};

  background: ${props => props.disabled ? props.theme.dangerColor + 'cf' : props.theme.dangerColor};

  :hover {
    background: ${props => props.theme.dangerColor + 'cf'};
    cursor: pointer;
  }
`

export const BasicInfoContainer = styled.div`
  display: grid;
  place-items: center;
`

export const BasicInfoModal = styled.div`
  background-color: ${props => props.theme.primaryLighter};

  padding: 2em;
  margin: 2em;
  border-radius: 1em;
  
  @media(max-width: 500px){
    margin: 0.5em;
    
  }
`

