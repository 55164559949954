import React from 'react';
import {BasicInfoContainer, BasicInfoModal, ButtonLink, theme} from './styles';
import {Waves} from './Waves';

export const BasicInfoPage = (props) => {
    return (
        <React.Fragment>
            <BasicInfoContainer>
                <BasicInfoModal>
                    {props.children}
                </BasicInfoModal>
            </BasicInfoContainer>
            <Waves position='fixed' color={theme.tertiaryColor}/>
        </React.Fragment>
    )
}