import styled from 'styled-components';
import {PrimaryButton, TertiaryButton, theme} from '../styles';
import {useLocation, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useFirestore, useFunctions} from 'reactfire';
import {useUserWithProfile} from '../hooks';
import firebase from 'firebase';
import {ManagedUsersContainer} from './managed-users/ManagedUsers';
import {BoldSubHeading} from './RecentWeighIns';

const RegisterIntegrationContainer = styled.div`
  padding: 1em;
`

const WithingsActionsContainer = styled.div`
  display: grid;
  place-items: top center;
`

const searchStringToObject = (search) => {
    const params = new URLSearchParams(search);
    const obj = {};
    for (const [key, value] of params) {
        obj[key] = value;
    }
    return obj;
}

const withingsMeasurementToNativeMeasurement = (withingsMeasurement) => {
    const reading = withingsMeasurement.measures[0];
    const lbs = (reading.value * Math.pow(10, reading.unit) * 2.20462);
    const createdMillis = withingsMeasurement.created * 1000;
    const created = firebase.firestore.Timestamp.fromDate(new Date(createdMillis));
    const date = firebase.firestore.Timestamp.fromDate(new Date(withingsMeasurement.date * 1000));

    const nativeMeasurement = {
        weight: lbs,
        createdOn: created,
        timestamp: date,
        source: 'WITHINGS'
    };

    return [createdMillis, nativeMeasurement];
}

/**
 * TODO:
 *  - Better options when linking
 *      - only sync future weights
 *      - sync weights since <date>
 *  - Ability to unlink
 *      - delete all measurements with source: WITHINGS
 */
export const RegisterWithings = (props) => {
    let location = useLocation();
    const {data: user} = useUserWithProfile();

    console.log(user);

    const userRef = useFirestore()
        .collection('users')
        .doc(user?.uid);

    const functions = useFunctions();

    const refreshToken = async () => {
        const {data: params} = await functions.httpsCallable('refreshWithingsToken')();
        console.log(params);
        const dateMillis = Date.now() + parseInt(params.expires_in, 10) * 1000;
        await userRef.update({
            withingsConfig: {
                ...params,
                expiresAt: firebase.firestore.Timestamp.fromMillis(dateMillis)
            }
        })
    }

    const getMeasurements = async () => {
        const headers = {
            'Authorization': `Bearer ${user.withingsConfig.access_token}`
        }

        const body = new URLSearchParams({
            action: 'getmeas',
            meastype: '1',
            category: '1'
        })

        const url = 'https://wbsapi.withings.net/measure'

        const result = await fetch(url, {method: 'POST', headers, body})
        const data = await result.json();
        const measurements = Object.fromEntries(data.body.measuregrps.map(withingsMeasurementToNativeMeasurement));

        await userRef.update(
            {measurements}
        );
    }

    useEffect(() => {
        const params = searchStringToObject(location.search);

        if (params?.access_token && !user.withingsConfig) {
            const dateMillis = Date.now() + parseInt(params.expires_in, 10) * 1000;
            userRef.update({
                withingsConfig: {
                    ...params,
                    expiresAt: firebase.firestore.Timestamp.fromMillis(dateMillis)
                }
            })
                .then(console.log)
                .catch(console.error);
        }

    }, [location.search])

    const logInWithWithings = async () => {

        const searchParams = {
            response_type: 'code',
            client_id: process.env.REACT_APP_WITHINGS_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_WITHINGS_REDIRECT_URI,
            scope: 'user.metrics',
            state: Date.now().toString(10)
        };

        const searchString = Object.entries(searchParams).map(([k, v]) => `${k}=${v}`).join('&')

        const url = `https://account.withings.com/oauth2_user/authorize2?${searchString}`;

        console.log(url);

        window.open(url);
    }

    return (
        <RegisterIntegrationContainer>
            {user.withingsConfig
                ? <WithingsActionsContainer>
                    <ManagedUsersContainer>
                        <BoldSubHeading style={{color: theme.dangerColor}}> WARNING</BoldSubHeading>
                        <p>
                            This is pretty unstable currently.
                            If you connect your scale it will overwrite all other measurements, this is not reversible.
                        </p>
                        <p>
                            Assuming you're cool with all of that, click 'Get Measurements' below. It will sync stuff.
                        </p>
                    <TertiaryButton onClick={refreshToken}> Refresh Withings </TertiaryButton>
                    <TertiaryButton onClick={getMeasurements}> Get Measurements </TertiaryButton>
                    </ManagedUsersContainer>
                </WithingsActionsContainer>
                : <WithingsActionsContainer>
                    <TertiaryButton onClick={logInWithWithings}> Log In to Withings </TertiaryButton>
                </WithingsActionsContainer>
            }
        </RegisterIntegrationContainer>
    )
}