import {BasicInfoPage} from '../BasicInfoPage';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useFirestore, useFirestoreDocData} from 'reactfire';
import styled from 'styled-components';

const FormulaPre = styled.pre`
  background-color: ${props => props.theme.primaryLight};
  color: ${props => props.theme.primaryDark};
  font-size: 0.9em;
  padding: 0.2em;
  text-align: center;
  border-radius: 0.3em;
  white-space: pre-wrap;
  font-family: Monaco, monospace;
  
  @media(max-width: 500px){
    font-size: 0.7em;
  }
`

const GoalSpan = styled.span`
  background-color: ${props => props.theme.tertiaryColor};
  padding: 0.3em;
  text-align: center;
  border-radius: 0.3em;
  margin: 0 0.5em;
`

const goalDescriptionFromConfig = (config) => {
    switch (config.keyMetric) {
        case 'percentChange':
            return (
                <React.Fragment>
                    <p>
                        Progress in the pool is measured by what percentage of your weight
                        you {config.goalDirection === 'positive' ? 'gain' : 'lose'} within
                        the duration of the pool. We calculate this as:
                        <FormulaPre>
                            percentChange = (endingWeight - startingWeight) / startingWeight
                        </FormulaPre>
                        Your actual weight is not visible by members of the pool, as this value is calculated
                        behind the scenes.
                    </p>
                    {config.winCondition === 'achieveGoal'
                        ? <p>
                            This pool has a goal of
                            <GoalSpan> {(100 * config.goal).toFixed(0)}%</GoalSpan> Anyone
                            who {config.goalDirection === 'positive' ? 'gains' : 'loses'} this amount will win.
                        </p>
                        : <p>
                            The winner of this pool will be the one
                            who {config.goalDirection === 'positive' ? 'gains' : 'loses'} the most.
                        </p>
                    }
                </React.Fragment>
            )
    }
}

const ReadableColumn = styled.div`
  max-width: 700px;
  width: 100%;
`

export const PoolRulesPage = (props) => {
    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);
    const {data: pool} = useFirestoreDocData(poolRef);

    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};

    return (
        <BasicInfoPage>
            <ReadableColumn>
                <h1> {pool.name} Rules </h1>
                <p>
                    The pool runs
                    from {pool.starts.toDate().toLocaleDateString('en-US', dateOptions)} to {pool.ends.toDate().toLocaleDateString('en-US', dateOptions)}.
                </p>
                {goalDescriptionFromConfig(pool.config)}

            </ReadableColumn>
        </BasicInfoPage>
    )
}
