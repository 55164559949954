import {useUserWithProfile} from '../../hooks';
import {useFirestore, useFirestoreCollectionData} from 'reactfire';
import {ManagedUserActions} from './ManagedUserActions';
import styled from 'styled-components';
import {BoldSubHeading} from '../RecentWeighIns';

export const ManagedUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  border-radius: 0.5em;
  background-color: ${props => props.theme.primaryLighter};
`

export const ManagedUsers = (props) => {
    const {data: user} = useUserWithProfile();

    const managedUsersRef = useFirestore()
        .collection('users')
        .doc(user.uid)
        .collection('managedUsers');

    const {data: managedUsers} = useFirestoreCollectionData(managedUsersRef, {idField: 'id'});


    return (
        <ManagedUsersContainer>
            <BoldSubHeading> Managed Users </BoldSubHeading>
            <table>
                <tbody>
                    {managedUsers.map(it => <ManagedUserActions userId={it.id}/>)}
                </tbody>
            </table>
        </ManagedUsersContainer>
    )
}