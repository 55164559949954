import styled, {css} from 'styled-components'
import poolImage from './resources/poolImage.png';
import {LargeImage} from './LandingPage';
import firebase from 'firebase/app';
import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from './input-components';
import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Waves} from './Waves';
import {ButtonLink, InlineLink, InvertedButtonLink, PrimaryButton, TertiaryButton, theme} from './styles';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {SingleDatePicker, DateRangePicker} from "react-dates";
import {useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce, useUser} from 'reactfire';
import {useBreakpoint, useUserWithProfile} from './hooks';
import {HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION, VERTICAL_SCROLLABLE} from 'react-dates/constants';
import moment from 'moment';

export const CenteredPageContainer = styled.div`
  display: grid;
  place-items: center;
`

export const ContentAndImageContainer = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 10;
  position: relative;
`

const DateSelectContainer = styled.div`
  display: grid;
  place-items: center;
`

const GoHomeButton = styled(ButtonLink)`
  background-color: ${props => props.theme.primaryLight};
`

const WeighInSuccessActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media(max-width: 600px){
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
    //background-color: ${props => props.theme.primaryLighter};
  //border-radius: 1em;
  //padding: 1em;
  margin-bottom: 1em;
`

const WeighInActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const WeighInPage = (props) => {
    const {forUser, editExisting} = props;
    const {register, handleSubmit, watch, formState: {errors}, control} = useForm({defaultValues: editExisting});
    const [status, setStatus] = useState('unsubmitted');
    const size = useBreakpoint();
    const {data: loggedInUser} = useUserWithProfile();
    const toTimeStamp = firebase.firestore.Timestamp.fromDate

    const uid = forUser || loggedInUser.uid;

    const usersRef = useFirestore()
        .collection('users')
        .doc(uid);

    const {data: user} = useFirestoreDocDataOnce(usersRef);

    const deleteWeighIn = async () => {
        setStatus('submitting');
        const weighInKey = `measurements.${editExisting.id}`
        console.log(weighInKey);
        await usersRef
            .update({
                [weighInKey]: firebase.firestore.FieldValue.delete()
            })
        setStatus('deleted');
    }

    const onSubmit = async (data) => {
        setStatus('submitting');
        console.log('Saving with: ', data);

        const weighInTimestamp = toTimeStamp(data.date.toDate());
        const weighIn = {
            timestamp: weighInTimestamp,
            weight: Number(data.weight),
            createdOn: firebase.firestore.FieldValue.serverTimestamp()
        }

        const weighInKey = editExisting ? `measurements.${editExisting.id}` : `measurements.${Date.now()}`
        try {
            await usersRef
                .update({
                    [weighInKey]: weighIn
                })
            setStatus('success');
        } catch (error) {
            console.error(error);
            setStatus('error');
        }
    }
    const [isFocused, setIsFocused] = useState(false);

    function onFocusChange({focused}) {
        setIsFocused(focused);
    }

    return (
        <CenteredPageContainer>
            <ContentAndImageContainer>
                <FormContainer>
                    <TitleContainer>
                        {forUser
                            ? <h2> {editExisting ? 'Edit' : ''} Weigh In For {user.displayName} </h2>
                            : <h2> {editExisting ? 'Edit' : ''} Weigh In! </h2>
                        }
                        <small>
                            By default, your weight is never shared with anyone.
                            We calculate the % change behind the scenes
                            for your pool.
                        </small>
                    </TitleContainer>
                    <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup>
                            <InputLabel>
                                Weight (lbs)
                            </InputLabel>
                            <SignInInput
                                step='any'
                                inputmode="numeric"
                                type='number'
                                {...register("weight", {required: true})}
                            />
                            {errors.poolName
                                ? <ErrorText> This page would be pretty pointless if you don't enter anything
                                    here </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            <InputLabel>
                                Weigh In Date
                            </InputLabel>
                            <Controller
                                control={control}
                                defaultValue={editExisting ? moment(editExisting.timestamp.toMillis()) : moment()}
                                rules={{required: true}}
                                name="date"
                                render={({
                                             field: {onChange, onBlur, value, name, ref},
                                             fieldState: {invalid, isTouched, isDirty, error},
                                             formState,
                                         }) => (
                                    <SingleDatePicker
                                        block
                                        withPortal
                                        numberOfMonths={1}
                                        isOutsideRange={(date) => {
                                            return !date.isBefore(moment().add(1, 'days'));
                                        }}
                                        id="date_input"
                                        date={value ?? null}
                                        focused={isFocused}
                                        onDateChange={onChange}
                                        onFocusChange={onFocusChange}
                                    />
                                )}
                            />
                            {errors.date
                                ? <ErrorText> We can't make fancy graphs without a x-axis </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            {!['success', 'deleted'].includes(status)
                                ? <WeighInActions>
                                    <SubmitButton type="submit">
                                        {status === 'submitting'
                                            ? <i className="fas fa-spinner fa-spin"/>
                                            : 'Save Weight'
                                        }
                                    </SubmitButton>
                                    {editExisting
                                        ? <TertiaryButton type="button" onClick={deleteWeighIn}>
                                            {status === 'submitting'
                                                ? <i className="fas fa-spinner fa-spin"/>
                                                : 'Delete this weigh in'
                                            }
                                        </TertiaryButton>
                                        : null
                                    }
                                </WeighInActions>
                                : <InputLabel>
                                    {status === 'deleted'
                                        ? 'Great! We deleted that weigh in.'
                                        : 'Great! We have it saved.'
                                    }
                                    <WeighInSuccessActions>
                                        <InvertedButtonLink to={'/home'}> Go Home </InvertedButtonLink>
                                        <InvertedButtonLink to={'/profile'}> View Weigh Ins </InvertedButtonLink>
                                        <TertiaryButton onClick={() => setStatus(null)}> Weigh In
                                            Again </TertiaryButton>
                                    </WeighInSuccessActions>
                                </InputLabel>
                            }
                        </InputGroup>
                    </SignUpAndInForm>
                </FormContainer>
                <LargeImage src={poolImage}/>
            </ContentAndImageContainer>
            <Waves color={theme.tertiaryColor} position={'sticky'}/>
        </CenteredPageContainer>
    )
}