import styled, {css} from 'styled-components';
import 'chartjs-adapter-moment';

/**
 * poolConfig has a value 'keyMetric' which determines standings
 * and a value 'goalDirection' which indicates which values are better
 * than others
 *
 * e.g. keyMetric: percentLost and goalDirection: positive
 *  means the list will be sorted by percentLost descending
 *
 *  keyMetric: currentWeight and goalDirection: negative
 *  would sort the list ascending by current weight
 */
export const sortMembers = (members, config) => {
    const metric = config.keyMetric
    const direction = config.goalDirection === 'positive' ? -1 : 1;
    return members.sort((a, b) => {
        return direction * getMostRecentMeasurement(a)?.[metric] + -1 * direction * getMostRecentMeasurement(b)?.[metric]
    })
}

const PoolStandingsTable = styled.table`
  width: 100%;
  padding: 1em;
  background-color: ${props => props.theme.primaryLighter};
  border-radius: 0.5em;
`

const PoolStandingsTableHead = styled.thead`
  font-weight: bold;
`

const PoolStandingsRow = styled.tr`

`

const PoolStandingsRankStyles = css`
  text-align: start;
`
const PoolStandingsRank = styled.td`${PoolStandingsRankStyles}`
const PoolStandingsRankHeading = styled.th`${PoolStandingsRankStyles}`

const PoolStandingsNameStyles = css`
  text-align: start;
`
const PoolStandingsName = styled.td`${PoolStandingsNameStyles}`
const PoolStandingsNameHeading = styled.th`${PoolStandingsNameStyles}`

const poolStandingsMetricStyles = css`
  text-align: end;
`
const PoolStandingsMetric = styled.td`${poolStandingsMetricStyles}`
const PoolStandingsMetricHeading = styled.th`${poolStandingsMetricStyles}`

const getMostRecentMeasurement = (member) => {
    return Object.values(member.measurements || {}).sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis())[0];
}


export const PoolStandings = (props) => {
    const {members, config} = props;
    const sortedMembers = sortMembers(members, config);

    return (
        <div>
            <PoolStandingsTable>
                <PoolStandingsTableHead>
                    <tr>
                        <PoolStandingsRankHeading> Rank</PoolStandingsRankHeading>
                        <PoolStandingsNameHeading> Name</PoolStandingsNameHeading>
                        <PoolStandingsMetricHeading> % Change</PoolStandingsMetricHeading>
                    </tr>
                </PoolStandingsTableHead>
                <tbody>
                {sortedMembers.map((member, index) => (
                    <PoolStandingsRow key={member.uid}>
                        <PoolStandingsRank> {index + 1} </PoolStandingsRank>
                        <PoolStandingsName> {member.displayName} </PoolStandingsName>
                        <PoolStandingsMetric>
                            {getMostRecentMeasurement(member)
                                ? `${(100 * getMostRecentMeasurement(member)?.[config.keyMetric]).toFixed(1)} %`
                                : ''
                            }
                        </PoolStandingsMetric>
                    </PoolStandingsRow>
                ))}
                </tbody>
            </PoolStandingsTable>
        </div>
    )
}

