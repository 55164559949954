import {useManagedOrLoggedInUser, useUserWithProfile} from '../hooks';
import {RecentWeighIns} from './RecentWeighIns';
import React from 'react';
import styled from 'styled-components';
import {useFirestore, useFirestoreDocData, useUser} from 'reactfire';

const AllWeighInsContainer = styled.div`
  display: grid;
  place-items: center;
  margin: 1em;
`

export const AllWeighIns = (props) => {
    const {forUser} = props;
    const user = useManagedOrLoggedInUser(forUser);

    return (
        <AllWeighInsContainer>
            <RecentWeighIns
                forUser={forUser}
                measurementsToShow={'all'}
                user={user}/>
        </AllWeighInsContainer>
    )
}