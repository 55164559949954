import styled, {css} from 'styled-components'
import poolImage from './resources/poolImage.png';
import {LargeImage} from './LandingPage';
import firebase from 'firebase/app';
import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from './input-components';
import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Waves} from './Waves';
import {ButtonLink, InlineLink, theme} from './styles';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {SingleDatePicker, DateRangePicker} from "react-dates";
import {useFirebaseApp, useFirestore, useUser} from 'reactfire';
import {useBreakpoint, useUserWithProfile} from './hooks';
import {HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION, VERTICAL_SCROLLABLE} from 'react-dates/constants';

const CreatePoolPageContainer = styled.div`
  display: grid;
  place-items: center;
`

const FormAndImageContainer = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 10;
  position: relative;
`

const DateSelectContainer = styled.div`
  display: grid;
  place-items: center;
`

export const CreatePoolPage = (props) => {
    const {register, handleSubmit, watch, formState: {errors}, control} = useForm();
    const [status, setStatus] = useState('unsubmitted');
    const size = useBreakpoint();
    console.log(size);
    const user = useUserWithProfile();
    const newPoolRef = useFirestore()
        .collection('pools')
        .doc();

    const userRef = useFirestore()
        .collection('users')
        .doc(user.data.uid);

    const toTimeStamp = firebase.firestore.Timestamp.fromDate

    const firestore = useFirestore();

    const onSubmit = async (data) => {
        setStatus('submitting');
        console.log('Saving with: ', data);
        try {
            await firestore.runTransaction(async (transaction) => {

                const poolItem = {
                    name: data.poolName,
                    starts: toTimeStamp(data.dates.startDate.toDate()),
                    ends: toTimeStamp(data.dates.endDate.toDate()),
                    owner: user.data.uid,
                    config: {
                        keyMetric: 'percentChange',
                        winCondition: 'achieveGoal', // or 'rankedAwards'
                        goal: 0.04,
                        goalDirection: 'negative'
                    }
                }

                transaction.set(newPoolRef, poolItem);
                const path = `pools.${newPoolRef.id}`

                transaction.update(userRef, {
                    [path]: poolItem
                });

                transaction.set(newPoolRef.collection('members').doc(user.data.uid), {
                        displayName: user.data.displayName,
                        email: user.data.email,
                        uid: user.data.uid,
                        role: 'owner',
                    });
            })
            setStatus('success');
        } catch (error) {
            console.error(error);
            setStatus('error');
        }
    }
    const [isFocused, setIsFocused] = useState(false);

    function onFocusChange(focused) {
        setIsFocused(focused);
    }

    return (
        <CreatePoolPageContainer>
            <FormAndImageContainer>
                <FormContainer>
                    <h2> Create a Pool </h2>
                    <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup>
                            <InputLabel>
                                Pool Name
                            </InputLabel>
                            <SignInInput {...register("poolName", {required: true})} />
                            {errors.poolName
                                ? <ErrorText> Beuller? </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            <InputLabel>
                                Pool Duration
                            </InputLabel>
                            <Controller
                                control={control}
                                rules={{required: true}}
                                name="dates"
                                render={({
                                             field: {onChange, onBlur, value, name, ref},
                                             fieldState: {invalid, isTouched, isDirty, error},
                                             formState,
                                         }) => (
                                    <DateRangePicker
                                        block
                                        withPortal
                                        orientation={size > 500 ? HORIZONTAL_ORIENTATION : VERTICAL_SCROLLABLE}
                                        startDate={value?.startDate ?? null} // momentPropTypes.momentObj or null,
                                        isOutsideRange={(date) => false}
                                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                        endDate={value?.endDate ?? null} // momentPropTypes.momentObj or null,
                                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                        onDatesChange={onChange} // PropTypes.func.isRequired,
                                        focusedInput={isFocused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={onFocusChange} // PropTypes.func.isRequired,
                                    />
                                )}
                            />
                            {errors.dates
                                ? <ErrorText> You have to tell us how long this thing is going to last</ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            {status !== 'success'
                                ? <SubmitButton type="submit">
                                    {status === 'submitting'
                                        ? <i className="fas fa-spinner fa-spin"/>
                                        : 'Create Your Pool'
                                    }
                                </SubmitButton>
                                : <InputLabel>
                                    See, that wasn't bad! Check out your shiny new pool
                                    <ButtonLink to={'/home'}> here </ButtonLink>
                                </InputLabel>
                            }
                        </InputGroup>
                    </SignUpAndInForm>
                </FormContainer>
                <LargeImage src={poolImage}/>
            </FormAndImageContainer>
            <Waves color={theme.tertiaryColor} position={'sticky'}/>
        </CreatePoolPageContainer>
    )
}