import {useAuth, useSigninCheck} from 'reactfire';
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {SplashScreen} from '../SplashScreen';
import {useForm} from 'react-hook-form';
import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from '../input-components';

export const ConfirmEmailSignIn = () => {
    const auth = useAuth();
    const [signInAttempted, setSignInAttempted] = useState(false);
    const [newAccount, setNewAccount] = useState(false);
    const [invitationCode, setInvitationCode] = useState(null);
    const [email, setEmail] = useState(null);
    const {status, data: {signedIn, user}} = useSigninCheck();


    const handleSignInWithLink = (result) => {
        const {invitationCode} = Object.fromEntries(new URLSearchParams(window.location.search));
        console.log(result, {invitationCode});
        setInvitationCode(invitationCode);
        setNewAccount(result.additionalUserInfo.isNewUser);
        setSignInAttempted(true);
    }


    useEffect(() => {
        setEmail(window.localStorage.getItem('emailForSignIn'));
    })

    useEffect(() => {
        if (email && auth.isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            auth.signInWithEmailLink(email, window.location.href)
                .then(handleSignInWithLink)
                .catch(result => {
                    setSignInAttempted(true);
                })
        }
    }, [window.location.href])

    console.log({signedIn, newAccount})

    const {register, handleSubmit, watch, formState: {errors}} = useForm();

    const onSubmit = async data => {
        auth.signInWithEmailLink(data.email, window.location.href)
            .then(handleSignInWithLink)
            .catch(result => {
                setSignInAttempted(true);
            })
    }


    if (signedIn) {
        if (newAccount) {
            return <Redirect to={{pathname: "/create-account", state: {invitationCode}}}/>
        } else if(invitationCode){
            return <Redirect to={{pathname: `/pools/join/${invitationCode}`}}/>
        } else {
            return <Redirect to={{pathname: '/home'}}/>
        }
    } else if (signInAttempted) {
        return <h1> Looks like that link didn't work, try logging in again</h1>
    } else {
        return (
            /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
            <SignUpContainer>
                <h1> Confirm Your Email </h1>
                <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>

                    <InputGroup>
                        <InputLabel>
                            email
                        </InputLabel>
                        <SignInInput type='email' {...register("email", {required: true})} />
                        {errors.email && <ErrorText>We have to make sure the link you have is for you</ErrorText>}
                    </InputGroup>

                    <InputGroup>
                        {status === 'submitted'
                            ? <InputLabel> Signing In!</InputLabel>
                            : <SubmitButton type="submit">
                                {status === 'submitting'
                                    ? <i className="fas fa-spinner fa-spin"/>
                                    : 'Submit'
                                }
                            </SubmitButton>
                        }
                    </InputGroup>
                </SignUpAndInForm>
            </SignUpContainer>
        )
    }
}