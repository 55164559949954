import styled from 'styled-components';
import {darken, InlineLink} from '../styles';
import {useHistory, useLocation} from 'react-router-dom';
import {useManagedOrLoggedInUser} from '../hooks';

const RecentWeighInsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  border-radius: 0.5em;
  background-color: ${props => props.theme.primaryLighter};
`

const WeighInContentContainer = styled.div`
`

const MeasurementContainer = styled.tr`
  //display: flex;
  //flex-direction: row;
  //justify-content: space-between;
  :hover {
    color: ${props => darken(props.theme.primaryDark)};
  }

  cursor: pointer;
`
const MeasurementTableHeading = styled.th`
  text-align: start;
  padding: 0 0.5em;
`

const MeasurementData = styled.td`
  text-align: start;
  padding: 0 0.5em;
`

const MeasurementRow = (props) => {
    const {measurement, forUser} = props;
    const history = useHistory();

    const linkDestination = forUser
        ? `/managed-users/${forUser}/weigh-ins/${measurement.id}`
        : `/profile/weigh-ins/${measurement.id}`;

    return (
        <MeasurementContainer onClick={() => history.push(linkDestination)}>
            <MeasurementData>
                {measurement.weight.toFixed(1)}
            </MeasurementData>
            <MeasurementData>
                {measurement.timestamp.toDate().toLocaleDateString()}
            </MeasurementData>
            <MeasurementData>
                Edit <i className="fas fa-external-link-square-alt"/>
            </MeasurementData>
        </MeasurementContainer>
    )
}

export const BoldSubHeading = styled.h2`
  text-align: start;
  font-weight: bold;
`

const WeighInsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const RecentWeighIns = (props) => {
    const {user, measurementsToShow = 4, forUser} = props;
    const measurementList = Object.entries(user.measurements || {}).map(([a, b]) => ({id: a, ...b}));
    const sortedMeasurements = measurementList.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());
    const truncatedMeasurements = measurementsToShow !== 'all' ? sortedMeasurements.slice(0, measurementsToShow + 1) : sortedMeasurements;

    const maybeManaged = useManagedOrLoggedInUser(forUser);

    return (
        <RecentWeighInsContainer>
            <WeighInsTitle>
                <BoldSubHeading>
                    {measurementsToShow !== 'all' ? 'Recent' : ''} Weigh Ins {forUser ? `for ${maybeManaged.displayName}` : null}
                </BoldSubHeading>
                {measurementsToShow !== 'all' ? <InlineLink to={'/profile/weigh-ins'}> View all </InlineLink> : null}
            </WeighInsTitle>
            <table>
                <thead>
                <tr>
                    <MeasurementTableHeading>
                        Weight
                    </MeasurementTableHeading>
                    <MeasurementTableHeading>
                        Date
                    </MeasurementTableHeading>
                </tr>
                </thead>
                <tbody>
                {truncatedMeasurements.map(it => <MeasurementRow measurement={it} forUser={forUser}/>)}
                </tbody>
            </table>
            <br/>
            <small> By default, your actual weight data is not shared with others in pools.</small>
        </RecentWeighInsContainer>
    )
}
