import React from 'react'
import {ButtonLink} from '../styles';
import {BasicInfoPage} from '../BasicInfoPage';

export const DeletePool = () => {
    return (
        <BasicInfoPage>
            <h1> Delete Pool </h1>
            <p> Let's be real. You created a pool just to test things out and now want to delete it.
                That's awesome! But now you're thinking, "I should probably delete this pool" then you wind up
                here.
            </p>
            <p>
                That's where you hit a snag. Instead of a whizzy 'Delete This Pool' page with a nice confirm
                dialog where you have to type a name, turn two keys, and
                <strike> launch the nukes </strike> delete your pool, there's just this long-winded 'TODO'.
            </p>
            <p>
                See, deleting a pool is kind of complicated, and I just haven't gotten to it yet.
                If you're seeing this page and want to delete a pool, just let me know and I'll talk to the
                guys in the back and get it sorted.

                You can consider it the worlds least responsive button. Sorry.
            </p>
            <ButtonLink to={'/home'}>
                Lets just head home and pretend this didn't happen.
            </ButtonLink>
        </BasicInfoPage>
    )
}
