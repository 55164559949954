import {useForm} from "react-hook-form";
import styled, {css} from 'styled-components';
import {buttonCss, PrimaryButton} from '../styles';
import {useAuth} from 'reactfire';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from '../input-components';

const useSignInWithEmailLink = (invitationCode = null) => {
    const auth = useAuth();
    const actionCodeSettings = {
        url: `http://${window.location.host}/confirm-sign-in${invitationCode ? '?invitationCode=' + invitationCode : ''}`,
        handleCodeInApp: true,
    };
    return async (email) => auth.sendSignInLinkToEmail(email, actionCodeSettings)
}

const useSignInWithEmailAndPassword = () => {
    const auth = useAuth();
    const actionCodeSettings = {
        url: `http://${window.location.host}/sign-in`,
        handleCodeInApp: true,
    };
    return async (email, password, newAcct = false) => {
        if (newAcct) {
            auth.createUserWithEmailAndPassword()
        }
    }
}

const JankyEmailDescription = styled.div`
  color: ${props => props.theme.primaryLighter};
`

const JankyEmailSpan = styled.span`
  color: ${props => props.theme.tertiaryColor};
`

export const SignUpAndIn = (props) => {
    const {signUp, invitationCode} = props;
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const [status, setStatus] = useState('unsubmitted');
    const sendEmailSignIn = useSignInWithEmailLink(invitationCode);

    const onSubmit = async data => {
        setStatus('submitting');
        await sendEmailSignIn(data.email);
        window.localStorage.setItem('emailForSignIn', data.email);
        setStatus('submitted');
    }

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <SignUpContainer>
            {signUp
                ? <h1> Sign Up </h1>
                : <h1> Sign In</h1>
            }
            <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>

                <InputGroup>
                    <InputLabel>
                        email
                    </InputLabel>
                    <SignInInput autocomplete="email" inputmode='email' type='email' {...register("email", {required: true})} />
                    {errors.email && <ErrorText>Whoops! Can't send an email if you don't give us one</ErrorText>}
                </InputGroup>

                <InputGroup>
                    {status === 'submitted'
                        ? <JankyEmailDescription>
                            <p>
                                Check your email and click the link to log in!
                                It will come from some random email
                                like <JankyEmailSpan> noreply@losing-rocks.firebaseapp.com </JankyEmailSpan>
                                It may look like spam, google is aware and they're on the case. You can click it though.
                                I promise.
                            </p>
                        </JankyEmailDescription>
                        : <SubmitButton type="submit">
                            {status === 'submitting'
                                ? <i className="fas fa-spinner fa-spin"/>
                                : signUp
                                    ? 'Submit'
                                    : 'Get a Sign In Link'
                            }
                        </SubmitButton>
                    }
                </InputGroup>
            </SignUpAndInForm>
        </SignUpContainer>
    );
}


SignUpAndIn.propTypes = {
    signUp: PropTypes.bool,
}