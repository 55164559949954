import {Route, Redirect} from 'react-router-dom';
import React, {useEffect} from 'react';
import LogRocket from 'logrocket';
import {useSigninCheck} from 'reactfire';

export function PrivateRoute({children, ...rest}) {
    const {status, data: {signedIn, user}} = useSigninCheck();

    if (status !== 'loading' && signedIn) {
        LogRocket.identify(user.uid, {
            name: user.displayName,
            email: user.email,
        });
    }

    return (
        status === 'loading'
            ? <span> Loading... </span>
            : <Route
                {...rest}
                render={({location}) =>
                    (signedIn) ? (
                        children
                    ) : (
                        <React.Fragment>
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: {from: location}
                                }}
                            />
                        </React.Fragment>
                    )
                }
            />
    );
}