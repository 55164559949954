import {ErrorBoundary} from 'react-error-boundary'
import {ButtonLink, InlineLink} from '../styles';
import {useFirestore, useFirestoreDocData} from 'reactfire';
import styled from 'styled-components';
import {PoolDuration} from './PoolHomePage';
import React from 'react';

const PoolSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.theme.primaryLighter};
  padding: 1em;
  border-radius: 0.3em;
  margin: 0.5em;
`

const PoolNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PoolSummary = (props) => {
    const {poolId} = props;

    const poolRef = useFirestore().collection('pools').doc(poolId);
    const {data: pool} = useFirestoreDocData(poolRef);

    return (
        <PoolSummaryContainer>
            <PoolNameContainer>
                <h2>{pool.name}</h2>
                <PoolDuration starts={pool.starts} ends={pool.ends}/>
            </PoolNameContainer>
            <ButtonLink to={`/pools/${poolId}`}> View Pool </ButtonLink>
        </PoolSummaryContainer>
    )

}