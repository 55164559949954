import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import React from 'react';
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser} from 'reactfire';
import {PoolStandings} from './PoolStandings';
import {Waves} from '../Waves';
import {InlineLink, theme} from '../styles';
import {MeasurementChart} from './MeasurementChart';

export const PoolHomePageContainer = styled.div`
  display: grid;
  place-items: center;

  min-height: 95vh;
`

export const PoolInfoContainer = styled.div`
  width: 80vw;

  @media (max-width: 600px) {
    width: 95vw;
  }
`

export const PoolDurationContainer = styled.div`
  text-align: end;
`

const firestoreTimestampToDateString = (timestamp) => {
    return timestamp?.toDate().toLocaleDateString('en-US');
}

export const PoolDuration = (props) => {
    const {starts, ends} = props;
    return (
        <PoolDurationContainer>
            {firestoreTimestampToDateString(starts)} - {firestoreTimestampToDateString(ends)}
        </PoolDurationContainer>
    )
}

export const PoolTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const PoolTitle = styled.div`
  display: flex;
  flex-direction: column;
`

export const PoolDetailsContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(300px, 1fr) 1fr;

  min-height: 40vh;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`


const userIsPoolAdmin = (user, poolMembers) => {
    if (user === null) {
        return false;
    } else {
        const userMember = poolMembers.find(it => it.uid === user.uid);
        return ['admin', 'owner'].includes(userMember.role);
    }
}

const useProtectedPoolInfo = (id) => {
    // const poolRef = useFirestore()
    //     .collection('pools')
    //     .doc(id)
    //     .collection('private')
    //     .doc('protected');
    //
    // try {
    //     const {data: pool} = useFirestoreDocData(poolRef);
    //     return pool;
    // } catch (error) {
    //     return null;
    // }
}

const TitleAndRulesLink = styled.span`
  display: flex;
  flex-direction: row;
  
  justify-content: space-between;
  align-items: baseline;
`

const PoolTitleHeading = styled.h1`
    padding-right: 1em;
`

export const PoolHomePage = (props) => {
    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);
    const {data: pool} = useFirestoreDocData(poolRef);
    const {data: user} = useUser();

    const poolMembersRef = poolRef.collection('members');
    const {data: poolMembers} = useFirestoreCollectionData(poolMembersRef);

    // const protectedData = useProtectedPoolInfo(id);

    return (
        <React.Fragment>
            <PoolHomePageContainer>
                <PoolInfoContainer>
                    <PoolTitleRow>
                        <PoolTitle>
                            <TitleAndRulesLink>
                                <PoolTitleHeading> {pool.name}</PoolTitleHeading>
                                <InlineLink to={`/pools/${id}/rules`}> Rules </InlineLink>
                            </TitleAndRulesLink>
                            {userIsPoolAdmin(user, poolMembers)
                                ? <InlineLink to={`/pools/${id}/manage`}>Manage</InlineLink>
                                : null
                            }
                        </PoolTitle>
                        <PoolDuration
                            starts={pool.starts}
                            ends={pool.ends}
                        />
                    </PoolTitleRow>
                    <PoolDetailsContainer>
                        <PoolStandings members={poolMembers} config={pool.config}/>
                        <MeasurementChart members={poolMembers} config={pool.config}/>
                    </PoolDetailsContainer>
                </PoolInfoContainer>
                <Waves color={theme.tertiaryColor} position='sticky'/>
            </PoolHomePageContainer>
        </React.Fragment>
    )
}