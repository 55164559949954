import {useEffect, useState} from 'react';
import {throttle} from 'lodash';
import {useFirestore, useFirestoreDocData, useUser} from 'reactfire';

const getDeviceConfig = (width) => {
    if (width < 320) {
        return 'xs';
    } else if (width >= 320 && width < 720) {
        return 'sm';
    } else if (width >= 720 && width < 1024) {
        return 'md';
    } else if (width >= 1024) {
        return 'lg';
    }
};

export const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => window.innerWidth);

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(window.innerWidth)
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
}

export const useUserWithProfile = () => {
    const {data: user} = useUser();
    const userRef = useFirestore()
        .collection('users')
        .doc(user?.uid)

    return useFirestoreDocData(userRef);
}


export const useManagedOrLoggedInUser = (managedUserId) => {
    const {data: loggedInUser} = useUser();

    const userRef = useFirestore()
        .collection('users')
        .doc(managedUserId || loggedInUser.uid);

    const {data: user} = useFirestoreDocData(userRef);

    return user;
}