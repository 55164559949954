import styled from 'styled-components';
import {WeighInPage} from '../WeighIn';
import {useParams} from 'react-router-dom';
import {useManagedOrLoggedInUser, useUserWithProfile} from '../hooks';


const EditWeighInContainer = styled.div`
    
`

export const EditWeighIn = (props) => {
    const {id, userId} = useParams();

    const user = useManagedOrLoggedInUser(userId);
    const measurement = {id, ...user.measurements[id]};

    console.log(id, user);

    return (
        <WeighInPage forUser={userId} editExisting={measurement}/>
    )
}