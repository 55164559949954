import {useFirestore, useFirestoreDocDataOnce} from 'reactfire';
import {InlineLink, PrimaryButton} from '../../styles';
import styled from 'styled-components';

const ManagedUserActionsRow = styled.tr`

`

export const ManagedUserActions = (props) => {
    const {userId} = props;

    const userRef = useFirestore()
        .collection('users')
        .doc(userId);

    const {data: user} = useFirestoreDocDataOnce(userRef, {idField: 'id'});

    return (
        <ManagedUserActionsRow>
            <td>
                <strong> {user.displayName} </strong>
            </td>
            <td>
                <InlineLink to={`/managed-users/${userId}/weigh-in`}>
                    Enter Weight
                </InlineLink>
            </td>
            <td>
                <InlineLink to={`/managed-users/${userId}/weigh-ins`}>
                    View Measurements
                </InlineLink>
            </td>
        </ManagedUserActionsRow>
    )
}