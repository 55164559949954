import {useFirestore, useFirestoreDocData} from 'reactfire';
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {ButtonLink, TertiaryButton, theme} from '../styles';
import {Waves} from '../Waves';
import {PoolDuration, PoolHomePageContainer, PoolInfoContainer, PoolTitle, PoolTitleRow} from './PoolHomePage';
import styled from 'styled-components';

const ManagePoolContainer = styled(PoolHomePageContainer)`
    place-items: start center;
`

const ManagePoolItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media(max-width: 600px){
    flex-direction: column;
  }
`

export const ManagePool = (props) => {
    const [copied, setCopied] = useState(false);
    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);

    const privatePoolRef = poolRef
        .collection('private')
        .doc('config');

    const {data: publicPoolData} = useFirestoreDocData(poolRef, {idField: 'id'});
    const {data: privatePoolData} = useFirestoreDocData(privatePoolRef);

    const pool = {
        ...privatePoolData,
        ...publicPoolData,
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 500);
        }
    }, [copied])

    const copyJoinCode = async () => {
        await navigator.clipboard.writeText(`http://${window.location.host}/pools/join/${pool.id}-${pool.invitationCode}`)
        setCopied(true);
    }

    return (
        <React.Fragment>
            <ManagePoolContainer>
                <PoolInfoContainer>
                    <ManagePoolItems>
                        <PoolTitle>
                            <h1> Manage {pool.name}</h1>
                        </PoolTitle>
                        <PoolDuration
                            starts={pool.starts}
                            ends={pool.ends}
                        />
                        <TertiaryButton onClick={copyJoinCode}>
                            <i className="fas fa-copy"/> &nbsp;
                            {copied ? 'Copied!' : 'Copy Invite Link'}
                        </TertiaryButton>
                        <ButtonLink to={`/pools/${id}/delete`}>
                            Delete this Pool
                        </ButtonLink>
                    </ManagePoolItems>
                </PoolInfoContainer>
                <Waves color={theme.tertiaryColor} position='fixed'/>
            </ManagePoolContainer>
        </React.Fragment>
    )
}