import {GlobalStyle, theme} from './styles';
import styled, {ThemeProvider} from 'styled-components';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {SuspenseWithPerf} from 'reactfire';
import {LandingPage} from './LandingPage';
import {AppContainer} from './AppContainer';
import {LoginPage} from './auth/LoginPage';
import {PrivateRoute} from './auth/PrivateRoute';
import {SplashScreen} from './SplashScreen';
import {ConfirmEmailSignIn} from './auth/ConfirmEmailSignIn';
import {CreateAccount} from './auth/CreateAccount';
import {HomePage} from './HomePage';
import {AuthCheck} from 'reactfire';
import {CreatePoolPage} from './CreatePool';
import {PoolHomePage} from './pools/PoolHomePage';
import {WeighInPage} from './WeighIn';
import {ManagePool} from './pools/ManagePool';
import {JoinPool} from './pools/JoinPool';
import {ErrorBoundary} from 'react-error-boundary';
import {DeletePool} from './pools/DeletePool';
import {ProfilePage} from './profile/ProfilePage';
import {EditWeighIn} from './profile/EditWeighIn';
import {RecentWeighIns} from './profile/RecentWeighIns';
import {AllWeighIns} from './profile/AllWeighIns';
import {ManagedUserWeighIn} from './profile/managed-users/ManagedUserWeighIn';
import {ManagedUserWeighIns} from './profile/managed-users/ManagedUserWeighIns';
import React from 'react';
import {PoolRulesPage} from './pools/PoolRules';
import {RegisterWithings} from './profile/RegisterWithings';


const AuthenticatedRoute = (props) => {
    return (
        <Route {...props}>
            <AuthCheck fallback={<LoginPage signIn/>}>
                {props.children}
            </AuthCheck>
        </Route>
    )
}

const ErrorContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`

const ErrorModal = styled.div`
  background-color: ${props => props.theme.primaryDark};
  color: ${props => props.theme.tertiaryColor};
  padding: 2em;
  border-radius: 1em;
`

const ErrorText = styled.div`
  background-color: ${props => props.theme.primaryLighter};
  color: ${props => props.theme.primaryDark};
  padding: 1em;
  border-radius: 0.3em;
`

const ErrorFallback = ({error, resetErrorBoundary}) => {
    return (
        <ErrorContainer role="alert">
            <ErrorModal>
                <h2>Something went wrong</h2>
                <ErrorText>
                    <pre>{error.name}</pre>
                    <pre>{error.message}</pre>
                </ErrorText>
                <p>
                    Sorry about that. We'll try and figure it out.
                </p>
            </ErrorModal>
        </ErrorContainer>
    )
}


const AuthRoutes = () => {
    return <React.Fragment>

    </React.Fragment>
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <SuspenseWithPerf fallback={<SplashScreen/>}>
                <BrowserRouter>
                    <AppContainer>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Switch>
                                <Route exact path='/'>
                                    <LandingPage/>
                                </Route>
                                <Route exact path='/sign-in'>
                                    <LoginPage/>
                                </Route>
                                <Route exact path='/sign-up'>
                                    <LoginPage signUp/>
                                </Route>
                                <Route exact path='/confirm-sign-in'>
                                    <ConfirmEmailSignIn/>
                                </Route>
                                <PrivateRoute exact path='/create-account'>
                                    <CreateAccount/>
                                </PrivateRoute>
                                <PrivateRoute exact path='/home'>
                                    <HomePage/>
                                </PrivateRoute>
                                <AuthenticatedRoute exact path='/create-pool'>
                                    <CreatePoolPage/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/weigh-in'>
                                    <WeighInPage/>
                                </AuthenticatedRoute>
                                <Route exact path='/pools/:id'>
                                    <PoolHomePage/>
                                </Route>
                                <Route exact path='/pools/:id/rules'>
                                    <PoolRulesPage/>
                                </Route>
                                <Route exact path='/pools/join/:invitationCode'>
                                    <JoinPool/>
                                </Route>
                                <AuthenticatedRoute exact path='/pools/:id/manage'>
                                    <ManagePool/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/pools/:id/delete'>
                                    <DeletePool/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/profile'>
                                    <ProfilePage/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/profile/weigh-ins'>
                                    <AllWeighIns/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/profile/weigh-ins/:id'>
                                    <EditWeighIn/>
                                </AuthenticatedRoute>
                                <Route exact path='/profile/integrations/withings'>
                                    <RegisterWithings/>
                                </Route>
                                <AuthenticatedRoute exact path='/managed-users/:userId/weigh-in'>
                                    <ManagedUserWeighIn/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/managed-users/:userId/weigh-ins'>
                                    <ManagedUserWeighIns/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/managed-users/:userId/weigh-ins/:id'>
                                    <EditWeighIn/>
                                </AuthenticatedRoute>
                            </Switch>
                        </ErrorBoundary>
                    </AppContainer>
                </BrowserRouter>
            </SuspenseWithPerf>
        </ThemeProvider>
    );
}

export default App;
