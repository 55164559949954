import {useFirestore, useFirestoreDocDataOnce, useFunctions, useSigninCheck} from 'reactfire';
import {SplashScreen} from '../SplashScreen';
import {SignUpAndIn} from '../auth/SignUpAndIn';
import {Redirect, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {ErrorText} from '../input-components';
import styled from 'styled-components';
import {Waves} from '../Waves';
import {theme} from '../styles';
import {LandingPage} from '../LandingPage';

const JoinPoolErrorContainer = styled.div`
  display: grid;
  place-items: center;
  height: 80vh;
`

const ErrorModal = styled.div`
  width: 90vw;
  max-width: 600px;
  padding: 2em;
  text-align: center;
  border-radius: 0.3em;
  font-size: 1.5em;
  background-color: ${props => props.theme.primaryDark};
`

/**
 *  if a user is logged in, add them to the pool
 *  if a user is logged out, prompt to sign in and attach a
 */
export const JoinPool = ({props}) => {
    const {status, data: {signedIn, user}} = useSigninCheck();
    const {invitationCode} = useParams();
    const [joinStatus, setJoinStatus] = useState(null);
    const [confirmJoinPool, setConfirmJoinPool] = useState(false);
    const joinPool = useFunctions().httpsCallable('joinPool');

    useEffect(() => {
        if (signedIn && invitationCode) {
            joinPool({invitationCode})
                .then(res => setJoinStatus({status: 'success'}))
                .catch(err => setJoinStatus({status: 'error', message: err.message}));
        }
    }, [signedIn, invitationCode])

    if (joinStatus) {
        if (joinStatus.status === 'success') {
            return <Redirect to={{pathname: '/home'}}/>
        } else {
            return (
                <React.Fragment>
                    <JoinPoolErrorContainer>
                        <ErrorModal>
                            <ErrorText> {joinStatus.message}</ErrorText>
                        </ErrorModal>
                    </JoinPoolErrorContainer>
                    <Waves color={theme.tertiaryColor} position='fixed'/>
                </React.Fragment>
            )
        }
    }
    if (status === 'loading') {
        return <SplashScreen/>
    } else if(!confirmJoinPool){
        return <LandingPage invited signUpAction={() => setConfirmJoinPool(true)}/>
    } else if (!signedIn) {
        return <SignUpAndIn invitationCode={invitationCode}/>
    } else {
        return <SplashScreen text='Joining the Pool!'/>
    }
}