import {InlineLink, theme} from '../styles';
import {LargeImage} from '../LandingPage';
import poolImage from '../resources/poolImage.png';
import {Waves} from '../Waves';
import {CenteredPageContainer} from '../WeighIn';
import {useUserWithProfile} from '../hooks';
import {BoldSubHeading, RecentWeighIns} from './RecentWeighIns';
import styled from 'styled-components';
import {MeasurementChart} from '../pools/MeasurementChart';
import React from 'react';
import {PoolStandings} from '../pools/PoolStandings';
import {
    PoolDetailsContainer,
    PoolDuration,
    PoolHomePageContainer,
    PoolInfoContainer, PoolTitle,
    PoolTitleRow
} from '../pools/PoolHomePage';
import {ManagedUsers, ManagedUsersContainer} from './managed-users/ManagedUsers';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100vw;
`

const ContentAndImageContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(300px, 1fr) 1fr;

  min-height: 40vh;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

export const ProfilePage = (props) => {
    const {data: user} = useUserWithProfile();

    const userConfig = {
        keyMetric: 'weight'
    }

    return (
        <React.Fragment>
            <PoolHomePageContainer>
                <PoolInfoContainer>
                    <PoolTitleRow>
                        <PoolTitle>
                            <h1> Your Profile </h1>
                        </PoolTitle>
                    </PoolTitleRow>
                    <PoolDetailsContainer>
                        <RecentWeighIns user={user}/>
                        <MeasurementChart members={[user]} config={userConfig}/>
                        <ManagedUsers/>
                        <ManagedUsersContainer>
                            <BoldSubHeading> Integrations </BoldSubHeading>
                            <InlineLink to={'/profile/integrations/withings'}> Connect a Withings Scale </InlineLink>
                        </ManagedUsersContainer>
                    </PoolDetailsContainer>
                </PoolInfoContainer>
                <Waves color={theme.tertiaryColor} position='sticky'/>
            </PoolHomePageContainer>
        </React.Fragment>
    )
}