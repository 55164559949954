import {Redirect, useHistory} from 'react-router-dom';
import {StyledFirebaseAuth} from "react-firebaseui";
import React, {useState} from "react";
import {authProviderIds} from "../index"
import {useAuth, useFirebaseApp, useSigninCheck, useUser} from 'reactfire';
import {SignUpAndIn} from './SignUpAndIn';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {theme} from '../styles';
import {Waves} from '../Waves';

export function LoginPage({signUp}) {
    const {status, data: {signedIn, user}} = useSigninCheck();
    const [loginSuccess, setLoginSuccess] = useState(false);

    return (
        <div>
            <div>
                {
                    status === 'loading'
                        ? <span>Loading...</span>
                        : signedIn || loginSuccess
                        ? <Redirect to={{pathname: "/home",}}/>
                        : <SignUpAndIn signUp={signUp}/>
                }
            </div>
            <Waves position='fixed' color={theme.tertiaryColor}/>
        </div>
    )
}

LoginPage.propTypes = {
    signUp: PropTypes.bool
}
