import styled from 'styled-components';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {sortMembers} from './PoolStandings';

const renderValueBasedOnConfig = (measurement, config) => {
    switch (config.keyMetric) {
        case 'percentChange':
            return (measurement?.[config.keyMetric] * 100).toFixed(3);
        default:
            return measurement.weight.toFixed(0);
    }
}

const getChartDataForMember = (member, poolConfig) => {
    const measurementsArray = Object.values(member.measurements || {});
    return measurementsArray.map(it => ({
        x: it.timestamp.toMillis(),
        y: renderValueBasedOnConfig(it, poolConfig)
    }))
}


const PoolChartContainer = styled.div`
  background-color: ${props => props.theme.primaryLighter};
  padding: 1em;
  background-color: ${props => props.theme.primaryLighter};
  border-radius: 0.5em;
  height: 100%;
  display: grid;
  place-items: center;
`

const randomColor = () => {
    const colors = [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#34495e',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
    ]

    return colors[Math.floor(Math.random() * colors.length)];
}

function hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "#" + "00000".substring(0, 6 - c.length) + c;
}

export const MeasurementChart = (props) => {
    const {members, config} = props;
    // const sortedMembers = sortMembers(members, config);

    const data = {
        datasets: members?.map(member => ({
            label: member.displayName,
            borderColor: member.graphColor ?? intToRGB(hashCode(member.displayName)),
            tension: 0.1,
            data: getChartDataForMember(member, config).sort((a, b) => b.x - a.x),
        }))
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    callback: function(val, index) {
                        return val;
                    },
                }
            },
            y: {
                title: {
                    display: true,
                    text: '% change'
                },
                ticks: {
                    callback: function(val, index) {
                        return val;
                    },
                }
            }
        },
        elements: {
            point:{
                radius: 0
            }
        },
        responsive: true,
        plugins: {
            legend: {
                label : {
                    font: {
                        family: 'Recoleta'
                    }
                }
            }
        }
    };

    return (
        <PoolChartContainer>
            <Line data={data} options={options}/>
        </PoolChartContainer>
    )
}